<template>
    <div
        v-if="isCreator === true"
        class="stairwell-creator"
        @click="createAction()"
    >
        <i class="fas fa-plus"></i>
        <h1 v-if="entityParent === 'residence'">
            {{ $t("stairwellCard.createBuilding") }}
        </h1>
        <h1 v-else-if="entityParent === 'building'">
            {{ $t("stairwellCard.createStairwell") }}
        </h1>
    </div>
    <div v-else class="stairwell">
        <div
            v-if="stairwell.type === stairwellType.BUILDING"
            class="resume building-type"
            :class="{ lastBuilding: stairwell.id == lastBuildingId }"
            @click="goToBuilding()"
        >
            <div class="name">
                <h1>
                    {{ stairwell.nom }}
                </h1>
                <div class="display-mobile">
                    {{
                        $t("stairwellCard.accesses", {
                            count: parseInt(stairwell.acces._count, 10),
                        })
                    }}
                </div>
                <img
                    :src="
                        require('@/assets/images/buildings/building-small.png')
                    "
                    width="70"
                />
            </div>
            <div class="infos">
                <basic-button class="housing-button" color="blue">
                    <i class="fas fa-door-closed"></i>
                    {{
                        $t("stairwellCard.stairwells", {
                            count: parseInt(stairwell.cages_count, 10),
                        })
                    }}
                </basic-button>
            </div>
        </div>
        <div
            v-else-if="stairwell.type === stairwellType.STAIRWELL"
            class="resume stairwell-type"
            :class="{ lastStairwell: stairwell.id == lastStairwellId }"
            @click="goToStairwell()"
        >
            <div class="name">
                <h1>
                    {{ stairwell.nom }}
                </h1>
                <div class="display-mobile">
                    {{
                        $t("stairwellCard.accesses", {
                            count: parseInt(stairwell.acces._count, 10),
                        })
                    }}
                </div>
                <img
                    :src="
                        require('@/assets/images/buildings/stairwell-small.png')
                    "
                    width="40"
                />
            </div>
            <div class="infos">
                <basic-button class="housing-button" color="blue">
                    <i class="fas fa-door-closed"></i>
                    {{
                        $t("stairwellCard.apartments", {
                            count: parseInt(stairwell.apparts_count, 10),
                        })
                    }}
                </basic-button>
            </div>
        </div>
        <div class="accesses-list hide-mobile">
            <h2>
                {{
                    $t("stairwellCard.accesses", {
                        count: parseInt(stairwell.acces._count, 10),
                    })
                }}
            </h2>
            <div v-if="showedAccesses.length > 0" class="list">
                <div class="card_zone">
                    <access-card
                        v-for="access in showedAccesses"
                        :key="access.id"
                        :access="access"
                        :idParent="entityParentId"
                        :entityParent="entityParent"
                        :display="displayMode"
                    ></access-card>
                </div>
                <div class="see-more-zone">
                    <div
                        class="see-more"
                        v-if="
                            stairwell.acces.list.length > 6 &&
                            stairwell.type === stairwellType.STAIRWELL
                        "
                        @click="goToStairwell()"
                    >
                        <span>
                            {{ $t("button.seeAll") }}
                            <i class="fas fa-chevron-circle-down"></i>
                        </span>
                    </div>
                    <div
                        class="see-more"
                        v-if="
                            stairwell.acces.list.length > 6 &&
                            stairwell.type === stairwellType.BUILDING
                        "
                        @click="goToBuilding()"
                    >
                        <span>
                            {{ $t("button.seeAll") }}
                            <i class="fas fa-chevron-circle-down"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div v-else class="list-empty">
                <access-card
                    :isCreator="true"
                    :idEntity="stairwell.id"
                    :typeEntity="stairwell.type"
                    :idParent="entityParentId"
                    :entityParent="entityParent"
                    :display="displayMode"
                ></access-card>
            </div>
        </div>
    </div>
</template>

<script>
import AccessCard from "@/components/entities/AccessCard.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import v1mixin from "@/mixins/v1.js"
import { stairwellType } from "@/enums"
import { mapState } from "vuex"

export default {
    name: "StairwellCard",
    mixins: [v1mixin],
    components: {
        AccessCard,
        BasicButton,
    },
    props: {
        stairwell: {
            type: Object,
            default: function () {
                return null
            },
        },
        isCreator: {
            type: Boolean,
            default: false,
        },
        entityParent: {
            type: String,
            default: "",
        },
        entityParentId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            stairwellType,
            lastBuildingId: null,
            lastStairwellId: null,
            displayMode: "card",
        }
    },
    computed: {
        showedAccesses() {
            return this.stairwell.acces.list.slice(0, 6)
        },
    },
    methods: {
        goToStairwell() {
            this.$router.push({
                name: "Stairwell",
                params: {
                    id: this.stairwell.id,
                },
            })
        },
        goToBuilding() {
            this.$router.push({
                name: "Building",
                params: {
                    id: this.stairwell.id,
                },
            })
            this.$store.commit(
                "account/setBuildingId",
                parseInt(this.stairwell.id)
            )
            this.$store.commit("account/setStairwellId", null)
        },
        createAction() {
            switch (this.entityParent) {
                case "building":
                    this.openV1Modal("data/batiment_add", "Act_Zone")
                    break
                case "residence":
                    this.openV1Modal("data/residence/add", "Act_Zone")
                    break
            }
        },
    },
    created() {
        if (this.accesses === null) {
            this.loadAccesses()
        } else {
            this.allAccesses = this.accesses
        }

        // on recupere le dernier batiment cliqué
        if (this.$store.getters["account/getBuildingId"] !== null) {
            this.lastBuildingId = this.$store.getters["account/getBuildingId"]
        }

        // on recupere la derniere cage cliquée
        if (this.$store.getters["account/getStairwellId"] !== null) {
            this.lastStairwellId = this.$store.getters["account/getStairwellId"]
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.display-mobile {
    @media all and (min-width: 768px) {
        display: none;
    }

    @media all and (max-width: 768px) {
        margin-top: -20px;
        margin-bottom: 10px;
    }
}

.hide-mobile {
    @media all and (max-width: 768px) {
        display: none;
    }
}

.stairwell {
    display: flex;

    .stairwell-type {
        .name {
            h1 {
                margin-bottom: 22px;
            }
        }
    }

    .building-type {
        .name {
            h1 {
                margin-bottom: 14px;
            }
        }
    }

    .resume {
        min-width: 225px;
        height: 225px;
        cursor: pointer;
        box-shadow: 0px 3px 30px 0 rgb(0 0 0 / 15%);
        border-radius: 30px;
        transition: box-shadow 200ms;

        .name {
            height: 60%;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;
            border-radius: 30px 30px 0 0;

            h1 {
                font-size: $big;
                font-family: "Avenir Heavy";
            }
        }

        .infos {
            height: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 0 30px 30px;

            .housing-button {
                width: 180px;

                i {
                    margin-right: 15px;
                }
            }
        }
    }

    .resume:hover {
        box-shadow: 0px 3px 30px 0 rgb(0 0 0 / 25%);
    }

    .accesses-list {
        text-align: center;
        box-shadow: 0px 3px 30px 0 rgb(0 0 0 / 15%);
        border-radius: 30px;
        margin-left: 30px;
        margin-right: 0;
        width: 100%;
        height: 225px;
        padding: 15px 0 0 20px;
        overflow-y: auto;

        h2 {
            font-size: $normal;
            margin-bottom: 15px;
            font-family: "Avenir Heavy";
        }

        .list {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-content: center;
            align-items: center;

            .card_zone {
                display: grid;
                @media all and (max-width: 1090px) {
                    grid-template-columns: repeat(1, 1fr);
                }
                @media (min-width: 1090px) and (max-width: 1314px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media all and (min-width: 1314px) {
                    grid-template-columns: repeat(3, 1fr);
                }
                justify-content: flex-start;
                align-content: center;
                align-items: center;
                gap: 5px;
                width: max-content;
                height: 100%;

                .access-card {
                    width: 12vw;
                }
            }

            .see-more-zone {
                width: 20%;
                height: 100%;
            }
        }

        .list-empty {
            display: flex;
            justify-content: center;
        }
    }
}

.stairwell-creator {
    background-color: $orange-neutral;
    color: $white;
    border: none;
    width: max-content;
    height: 60px;
    padding: 1em 3em;
    gap: 1em;
    cursor: pointer;
    border-radius: 15px;
    transition: background-color 200ms;
    display: flex;
    align-items: center;

    i {
        color: $white;
        font-size: $big;
    }

    h1 {
        font-size: $normal;
        font-family: $font_avenir_heavy;
        color: $white;
        margin: 0;
    }
}

.stairwell-creator:hover {
    background-color: $orange-light;
}

.see-more {
    cursor: pointer;

    i {
        margin-right: 10px;
    }
}

.see-more:hover {
    color: $orange-neutral;
}

.lastBuilding {
    box-shadow: inset 1px 1px 10px rgb(0 0 0 / 15%), 2px 2px 10px #668899 !important;
}

.lastStairwell {
    box-shadow: inset 1px 1px 10px rgb(0 0 0 / 15%), 2px 2px 10px #668899 !important;
}
</style>
